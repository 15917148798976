import { extendTheme } from "@chakra-ui/react"


// This seems to require a full npm run develop restart
const theme = extendTheme({
    fonts: {
        body: "Roboto",
        heading: "Roboto",
        mono: "Menlo, monospace",
      },
    components: {
        Button: {
          // 1. We can update the base styles
          baseStyle: {
            fontWeight: "bold", // Normally, it is "semibold"
          },
          // 2. We can add a new button size or extend existing
          sizes: {
            xl: {
              h: "56px",
              fontSize: "lg",
              px: "32px",
            },
          },
          // 3. We can add a new visual variant
          variants: {
            "with-shadow": {
              bg: "red.400",
              boxShadow: "0 0 2px 2px #efdfde",
            },
            // 4. We can override existing variants
            solid: (props) => ({
            //   bg: props.colorMode === "dark" ? "red.300" : "red.700",
            }),
          },
        },
      },
    colors: {
      brand: {
        100: "#1a202c",
        900: "#1a202c",
      },
    },
  })
  export default theme