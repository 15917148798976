/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

 exports.onInitialClientRender = () => {
    window.document.getElementById("mobileNavToggle").addEventListener('click', () => {
      document.body.classList.add('nav-active');
    });

    window.document.getElementById("mobileNavToggleClose").addEventListener('click', () => {
      document.body.classList.remove('nav-active');
     });

     window.addEventListener('resize', () => {
      if (window.innerWidth > 1024) {
         document.body.classList.remove('nav-active');   
      }
     });
  }