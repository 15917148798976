// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recruitment-database-js": () => import("./../../../src/pages/recruitment-database.js" /* webpackChunkName: "component---src-pages-recruitment-database-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-whistle-blowing-policy-js": () => import("./../../../src/pages/whistle-blowing-policy.js" /* webpackChunkName: "component---src-pages-whistle-blowing-policy-js" */),
  "component---src-templates-area-of-expertise-entry-js": () => import("./../../../src/templates/AreaOfExpertiseEntry.js" /* webpackChunkName: "component---src-templates-area-of-expertise-entry-js" */),
  "component---src-templates-gallery-entry-js": () => import("./../../../src/templates/GalleryEntry.js" /* webpackChunkName: "component---src-templates-gallery-entry-js" */),
  "component---src-templates-news-entry-js": () => import("./../../../src/templates/NewsEntry.js" /* webpackChunkName: "component---src-templates-news-entry-js" */),
  "component---src-templates-news-listing-js": () => import("./../../../src/templates/NewsListing.js" /* webpackChunkName: "component---src-templates-news-listing-js" */),
  "component---src-templates-newsletters-entry-js": () => import("./../../../src/templates/NewslettersEntry.js" /* webpackChunkName: "component---src-templates-newsletters-entry-js" */),
  "component---src-templates-newsletters-listing-js": () => import("./../../../src/templates/NewslettersListing.js" /* webpackChunkName: "component---src-templates-newsletters-listing-js" */),
  "component---src-templates-page-entry-js": () => import("./../../../src/templates/PageEntry.js" /* webpackChunkName: "component---src-templates-page-entry-js" */),
  "component---src-templates-page-listing-js": () => import("./../../../src/templates/PageListing.js" /* webpackChunkName: "component---src-templates-page-listing-js" */),
  "component---src-templates-projects-entry-js": () => import("./../../../src/templates/ProjectsEntry.js" /* webpackChunkName: "component---src-templates-projects-entry-js" */),
  "component---src-templates-projects-listing-js": () => import("./../../../src/templates/ProjectsListing.js" /* webpackChunkName: "component---src-templates-projects-listing-js" */),
  "component---src-templates-resource-entry-js": () => import("./../../../src/templates/ResourceEntry.js" /* webpackChunkName: "component---src-templates-resource-entry-js" */),
  "component---src-templates-resource-listing-js": () => import("./../../../src/templates/ResourceListing.js" /* webpackChunkName: "component---src-templates-resource-listing-js" */),
  "component---src-templates-staff-entry-js": () => import("./../../../src/templates/StaffEntry.js" /* webpackChunkName: "component---src-templates-staff-entry-js" */),
  "component---src-templates-staff-listing-js": () => import("./../../../src/templates/StaffListing.js" /* webpackChunkName: "component---src-templates-staff-listing-js" */),
  "component---src-templates-what-we-do-areas-of-expertise-entry-js": () => import("./../../../src/templates/WhatWeDoAreasOfExpertiseEntry.js" /* webpackChunkName: "component---src-templates-what-we-do-areas-of-expertise-entry-js" */),
  "component---src-templates-what-we-do-focus-areas-entry-js": () => import("./../../../src/templates/WhatWeDoFocusAreasEntry.js" /* webpackChunkName: "component---src-templates-what-we-do-focus-areas-entry-js" */),
  "component---src-templates-what-we-do-overview-entry-js": () => import("./../../../src/templates/WhatWeDoOverviewEntry.js" /* webpackChunkName: "component---src-templates-what-we-do-overview-entry-js" */),
  "component---src-templates-what-we-do-projects-entry-js": () => import("./../../../src/templates/WhatWeDoProjectsEntry.js" /* webpackChunkName: "component---src-templates-what-we-do-projects-entry-js" */),
  "component---src-templates-what-we-do-team-entry-js": () => import("./../../../src/templates/WhatWeDoTeamEntry.js" /* webpackChunkName: "component---src-templates-what-we-do-team-entry-js" */)
}

